'use client';

import { tokens } from '@/styles/tokens';
import { Link } from '@chakra-ui/next-js';
import { Flex, Heading, Text } from '@chakra-ui/react';

export default function NotFound() {
  return (
    <Flex flexDirection={'column'} textAlign={'center'}>
      <Heading fontSize={'lg'}>Página não encontrada</Heading>
      <Text>A página acessada não foi encontrada em nosso sistema.</Text>
      <Link href='/' marginTop={7} color={tokens.text.primary.color}>
        <Text>Retornar a página inicial</Text>
      </Link>
    </Flex>
  );
}
